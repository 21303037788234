/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouteLocationNormalized,
  NavigationGuardNext
} from "vue-router";
import { I18n } from "vue-i18n";
import Home from "../views/Home.vue";

const setupRouter = function (i18n: I18n<any, any, any, any>) {
  const meta = {
    title: "Madarion | Digital innovation & beyond",
    description: i18n.global.t("home.presentation")
  };

  const routes: Array<RouteRecordRaw> = [
    {
      path: "/",
      name: "Home",
      component: Home
    }
  ];

  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  });

  router.beforeEach(
    (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) => {
      /* It will change the title when the router is change*/
      if (to.meta.title) {
        document.title = to.meta.title;
      } else {
        document.title = meta.title;
        const metas = document.head.getElementsByTagName("meta");
        for (const item of metas) {
          if (
            ["description"].includes(item.name) ||
            ["og:description"].includes(item.getAttribute("property") ?? "")
          ) {
            item.content = meta.description;
          }

          if (["og:title"].includes(item.getAttribute("property") ?? "")) {
            item.content = meta.title;
          }
        }
      }
      next();
    }
  );

  return router;
};

export default setupRouter;
