
import { Options, Vue } from "vue-class-component";

@Options({})
export default class Home extends Vue {
  navBarScrolled = false;

  mounted() {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        this.navBarScrolled = true;
      } else {
        this.navBarScrolled = false;
      }
    });
  }

  getYear() {
    return new Date().getFullYear();
  }
}
