<template>
  <nav
    id="mainNav"
    :class="{
      'h-5': navBarScrolled,
      'navbar-scrolled': navBarScrolled,
      navbar: true,
      'navbar-expand-lg': true,
      'navbar-light': false,
      'fixed-top': true
    }"
  >
    <div class="container">
      <span class="row">
        <a class="h-75" href="/">
          <img
            style="max-height: 3vh"
            src="../assets/images/mdn-logo-text.png"
            alt="Madarion logo"
          />
        </a>
        <span :style="{ display: navBarScrolled ? 'none' : 'block' }">
          Digital innovation & beyond
        </span>
      </span>
      <button
        class="navbar-toggler navbar-toggler-right border-0"
        type="button"
        data-toggle="collapse"
        data-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span data-feather="grid"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link page-scroll" href="#features">{{
              $t("home.solutions.menu")
            }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link page-scroll" href="#homeFooter">{{
              $t("home.contact")
            }}</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <!--hero header-->
  <section
    class="py-7 py-md-0 mdn-bg-space section-angle bottom-left"
    id="home"
  >
    <div class="container">
      <div class="row vh-md-100">
        <div class="col-md-8 col-sm-10 col-12 mx-auto my-auto text-center">
          <h1 class="heading-black text-capitalize">
            {{ $t("home.message") }}
          </h1>
          <p class="lead py-3">
            {{ $t("home.presentation") }}
          </p>
        </div>
      </div>
    </div>
  </section>

  <!-- features section -->
  <section class="pt-6 pb-7" id="features">
    <div class="container">
      <div class="row">
        <div class="col-md-6 mx-auto text-center">
          <h2 class="heading-black">{{ $t("home.solutions.title") }}</h2>
          <p class="text-muted lead">
            {{ $t("home.solutions.presentation") }}
          </p>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-10 mx-auto">
          <div class="row feature-boxes">
            <div class="col-md-6 box">
              <div class="mdn-icon-box">
                <span class="bi-layout-wtf" style="font-size: 3rem"></span>
              </div>
              <h5>{{ $t("home.solutions.architecture.title") }}</h5>
              <p class="text-muted">
                {{ $t("home.solutions.architecture.content") }}
              </p>
            </div>
            <div class="col-md-6 box">
              <div class="mdn-icon-box">
                <span class="bi-cloud" style="font-size: 3rem"></span>
              </div>
              <h5>{{ $t("home.solutions.cloud.title") }}</h5>
              <p class="text-muted">
                {{ $t("home.solutions.cloud.content") }}
              </p>
            </div>
            <div class="col-md-6 box">
              <div class="mdn-icon-box">
                <span class="bi-arrow-repeat" style="font-size: 3rem"></span>
              </div>
              <h5>{{ $t("home.solutions.devops.title") }}</h5>
              <p class="text-muted">
                {{ $t("home.solutions.devops.content") }}
              </p>
            </div>
            <div class="col-md-6 box">
              <div class="mdn-icon-box">
                <span class="bi-shield-check" style="font-size: 3rem"></span>
              </div>
              <h5>{{ $t("home.solutions.security.title") }}</h5>
              <p class="text-muted">
                {{ $t("home.solutions.security.content") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--footer-->
  <footer id="homeFooter" class="py-6">
    <div class="container">
      <div class="row">
        <div class="col-sm-5 mr-auto">
          <div class="row" style="margin-bottom: 1rem">
            <img
              class="col-sm2"
              src="../assets/images/mdn-logo-only-white.png"
              style="height: 30px; width: 60px"
              alt="Madarion logo"
            />
            <h5 class="col-sm-2" style="line-height: 2">madarion</h5>
            <span>Digital innovation & beyond</span>
          </div>
          <p class="text-muted row">
            <span
              class="bi-envelope col-sm-2"
              style="font-size: 1rem; width: auto"
            ></span>
            <span class="col-sm-2"
              ><a href="mailto:contact@madarion.com"
                >contact@madarion.com</a
              ></span
            >
          </p>
          <ul class="list-inline social social-sm">
            <li class="list-inline-item">
              <a href=""><i class="bi-facebook"></i></a>
            </li>
            <li class="list-inline-item">
              <a href=""><i class="bi-twitter"></i></a>
            </li>
            <li class="list-inline-item">
              <a href=""><i class="bi-linkedin"></i></a>
            </li>
          </ul>
        </div>
        <div class="col-sm-2">
          <h5>{{ $t("footer.legal") }}</h5>
          <ul class="list-unstyled">
            <li>
              <a href="#">{{ $t("footer.privacy") }}</a>
            </li>
            <li>
              <a href="#">{{ $t("footer.terms") }}</a>
            </li>
          </ul>
        </div>
        <div class="col-sm-2">
          <h5>{{ $t("footer.partner") }}</h5>
          <ul class="list-unstyled">
            <li>
              <a href="#">{{ $t("footer.affiliates") }}</a>
            </li>
          </ul>
        </div>
        <div class="col-sm-2">
          <h5>{{ $t("footer.help") }}</h5>
          <ul class="list-unstyled">
            <li>
              <a href="mailto:contact@madarion.com">{{
                $t("footer.support")
              }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12 text-muted text-center small-xl">
          &copy; {{ getYear() }} Madarion - All Rights Reserved
        </div>
      </div>
    </div>
  </footer>

  <!--scroll to top-->
  <div class="scroll-top">
    <i class="bi-journal-arrow-up" aria-hidden="true"></i>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({})
export default class Home extends Vue {
  navBarScrolled = false;

  mounted() {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        this.navBarScrolled = true;
      } else {
        this.navBarScrolled = false;
      }
    });
  }

  getYear() {
    return new Date().getFullYear();
  }
}
</script>

<style scoped>
.mdn-bg-space {
  background: linear-gradient(
      to bottom,
      rgba(104, 163, 172, 0.8) 0%,
      rgba(11, 27, 43, 0.8) 50%
    ),
    url(../assets/images/space.jpg);
  background-position-x: 0%, 0%;
  background-position-y: 0%, 0%;
  background-repeat: repeat, repeat;
  background-attachment: scroll, scroll;
  background-size: auto, auto;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
}

#features {
  background: linear-gradient(
      to bottom,
      rgba(104, 163, 172, 0.8) 0%,
      rgba(11, 27, 43, 0.8) 0%
    ),
    url(../assets/images/datacenter.jpg);
  background-position-x: 0%, 0%;
  background-position-y: 0%, 0%;
  background-repeat: repeat, repeat;
  background-attachment: scroll, scroll;
  background-size: auto, auto;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
}

.text-muted {
  color: #b8cee4 !important;
}

#mainNav {
  box-shadow: none;
  background-color: transparent;
  transition: background-color 0.2s ease;
}

#mainNav .nav-link {
  color: #132e46;
}
#mainNav.navbar-scrolled {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  background-color: #fff;
  margin-top: 0px !important;
}

.mdn-icon-box {
  padding: 5px;
}
</style>
