import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import App from "./App.vue";
import setupRouter from "./router";
import store from "./store";
import "./assets/styles/global.scss";

import fr from "./locales/fr.json";

const i18n = createI18n({
  locale: "fr",
  messages: {
    fr
  }
});

const router = setupRouter(i18n);

createApp(App).use(store).use(router).use(i18n).mount("#app");
